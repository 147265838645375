import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ISlide, ISlideObject, Slide, SlidePlaylist } from '../../shared/slide';
import { constants } from '../../_constant/constants';
import { environment } from '../../../environments/environment';
import { EventconfigStoreService } from '../../shared/eventconfig-store.service';
import { SlideStoreService } from '../../shared/slide-store.service';
import { timestamp } from 'rxjs';

@Component({
  selector: 'lse-slidelist-item',
  standalone: false,
  template: `
    <div class='mini_div'>
      @defer (on viewport){
      <img
        (click)='playbackAtTimestamp(slidePlaylistObject?.timestamp)'
        [ngClass]="{ highlight: this.selectedSlideObject?._id === slidePlaylistObject?._id }"
        class='mini_slide'
        src='{{environment.apiUrl}}/event/{{this.eventFolder}}/{{constant.SLIDES_FOLDER_PATH}}/{{this.slidePlaylistObjectSource}}_200.webp'
        height='70'
        width='120'
      />
      } @placeholder (minimum 100ms){
        <img
          class='mini_slide'
          src='assets/images/placeholder.jpg'
           height='70'
           width='120'>
      }
    </div>
<!--
  <div *ngIf="state === 'post-live'" class='mini_div'>
    <img
      (click)="playbackAtTimestamp()"
      class='mini_slide'
      [ngClass]="{ 'mini-highlight': highlight }"
&lt;!&ndash;      src='{{environment.apiUrl}}/event/{{this.eventFolder}}/{{constant.SLIDES_FOLDER_PATH}}/{{getActiveSlideNEW()}}_200.webp'&ndash;&gt;
      height='70'
      width='120'
      loading='lazy'
      (error)='handleMissingSlide($event)'
    />
  </div>-->
`,
  styleUrl: './../slidelist.component.css'
})
export class SlidelistItemComponent implements OnInit {
  @Input() slidePlaylistObject: SlidePlaylist;
  @Input() eventFolder: string;
  @Input() path: string;
  @Input() languages: string[];
  @Input() selectedLanguage: string;
  @Input() selectedSlide: ISlideObject;
  @Input() selectedSlideObject: SlidePlaylist;
  @Input() selectedSlideObjectIndex: number;



  @Output() vodEvent = new EventEmitter();
  @Output() centerSlide = new EventEmitter();
  @Output() showElement = new EventEmitter();
  @Output() playAtTimestamp = new EventEmitter();


  protected readonly constant = constants;
  protected readonly environment = environment;

  stateInterval: any;
  state: string;
  highlightInterval: any;
  highlight = false;
  noCacheNumber: number;
  slideDirection: string;

  eventConfigStoreService: EventconfigStoreService = inject(EventconfigStoreService);
  slideStoreService: SlideStoreService = inject(SlideStoreService);

  ngOnInit(): void {
    this.getEventState();
    this.stateInterval = setInterval(() => { // Initialisierung Interval zum Abruf des Status prelive, live, postlive.
      this.getEventState();
    }, 2000);
    this.slideDirection = this.eventConfigStoreService.eState === 'live' ? 'rtl' : 'ltr';
  }




  getEventState() {
    this.state = this.eventConfigStoreService.getEventState();
  }

  clickVodSlide() {
    console.log('clickVodSlide');
    this.eventConfigStoreService.setActiveSlideNEW(this.slidePlaylistObject);
    let seconds = this.slideTCtoSeconds(this.slidePlaylistObject.timestamp);
    this.vodEvent.emit(seconds);
    this.showElement.emit('slides');
  }

  playbackAtTimestamp(timestamp: string) {
    console.log('playbackAtTimestamp');
    this.playAtTimestamp.emit(this.slideTCtoSeconds(timestamp));
    this.eventConfigStoreService.setActiveSlideNEW(this.slidePlaylistObject);

  }

  slideTCtoSeconds(tc: string): number {
    let slideTimeCodeArray = tc.split(':');
    return (Number.parseInt(slideTimeCodeArray[0]) * 60 +
        Number.parseInt(slideTimeCodeArray[1])) * 60 +
      Number.parseInt(slideTimeCodeArray[2]);
  }

  get slidePlaylistObjectSource(): null | string {
    if (!this.slidePlaylistObject) {
      return null;
    }

    // First try: Check selected language
    if (this.slidePlaylistObject.slide[this.selectedLanguage]) {
      return this.slidePlaylistObject.slide[this.selectedLanguage];
    }

    // Second try: Check first language from languages array
    if (this.languages?.length > 0 && this.slidePlaylistObject.slide[this.languages[0]]) {
      return this.slidePlaylistObject.slide[this.languages[0]];
    }

    // Third try: Check second language from languages array
    if (this.languages?.length > 1 && this.slidePlaylistObject.slide[this.languages[1]]) {
      return this.slidePlaylistObject.slide[this.languages[1]];
    }

    // If nothing found, return null or a default value
    return null;
  }



}
