import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LogService } from './log.service';
import { SlidePlaylist } from './slide';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  BehaviorSubject,
  filter,
  Observable,
  pipe,
  ReplaySubject,
  Subject,
  Subscription,
  take,
  throwError
} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { io, Socket } from 'socket.io-client';
import { EventconfigStoreService } from './eventconfig-store.service';

@Injectable({
  providedIn: 'root',
})
export class SlideStoreService {

  http: HttpClient = inject(HttpClient);
  es: EventconfigStoreService = inject(EventconfigStoreService);


  slidePath: string;

  constructor(private logger: LogService) {}

  setSlidePath(path: string, lang: string) {
    // this.slidePath = environment.apiUrl + 'event/' + path + '/jpg_' + lang + '/';
    this.slidePath = environment.apiUrl + environment.eventPrefix + path + '/jpg_' + lang + '/';
    this.logger.log('Slide-Path: ' + this.slidePath);
  }
  getSlidePath() {
    this.logger.log('Get: ' + this.slidePath);
    return this.slidePath;
  }

  private playlistSource = new ReplaySubject<SlidePlaylist[]>(1);
  public readonly playlist$ = this.playlistSource.asObservable();


  public readonly _isPendingSlideConfig = new BehaviorSubject<boolean>(true);
  public readonly isPendingSlideConfig$ = this._isPendingSlideConfig.asObservable();


  private selectedSlideSource = new BehaviorSubject<SlidePlaylist | null>(null);
  selectedSlide$ = this.selectedSlideSource.asObservable()

  private scrollToSlideSubject = new Subject<SlidePlaylist>();
  scrollToSlide$ = this.scrollToSlideSubject.asObservable();

  triggerScrollToSlide(slide: SlidePlaylist) {
    this.scrollToSlideSubject.next(slide);
  }

  setSelectedSlide(slide: SlidePlaylist) {
    this.selectedSlideSource.next(slide);
  }

  getSelectedSlide(): Observable<any> {
    return this.selectedSlide$;
  }


  private subscriptions: Subscription[] = [];
  public lastSlideAddedId: string = '';

  // Socket-related properties
  public socket: Socket;
  private socketConnected = new BehaviorSubject<boolean>(false);
  public socketConnected$ = this.socketConnected.asObservable();

  SOCKET_ENDPOINT = environment.apiUrl;
  ///-----------------------------------------------------------------------------------------------------//////
  ///-----------------------------------------------------------------------------------------------------//////
  getSlidePlaylist() {
    this._isPendingSlideConfig.next(true);
    this.subscriptions.push(
      this.getSlidePlaylistFromApi().subscribe({
        next: (slidePlaylist: SlidePlaylist[]) => {
          // Emit different parts of the config to respective subjects
          this.playlistSource.next(slidePlaylist);
          this.selectedSlideSource.next(slidePlaylist.length > 0 ? slidePlaylist[slidePlaylist.length - 1] : slidePlaylist[0]);
          this._isPendingSlideConfig.next(false);
        },
        error: (err) => {
          console.error('Error fetching slide config', err);
          this._isPendingSlideConfig.next(false);
        }
      })
    );
  }

  // Modify API method to return full SlideConfig
  getSlidePlaylistFromApi(): Observable<SlidePlaylist[]> {
    return this.http.get<SlidePlaylist[]>(`${environment.apiUrl}/v1/event-backend/slidemanagement/getSlidePlaylistNEW`)
      .pipe(
        take(1),
        catchError((err: HttpErrorResponse) => {
          console.error('API Error', err);
          return throwError(() => err);
        })
      );
  }

  // Initialize socket connection
  public initializeSocketConnection() {
    this.socket = io(this.SOCKET_ENDPOINT + 'event_watcher', {
      path: '/v1/event-backend/socket/',
      auth: {
        token: this.es.getInitCipher(),
        customer: environment.customerShort,
        instance: 'event'
      }
    });

    // Socket connection events
    this.socket.on('connect', () => {
      console.log('Socket connected');
      this.socketConnected.next(true);
    });

    this.socket.on('disconnect', () => {
      console.log('Socket disconnected');
      this.socketConnected.next(false);
    });

    // Listen for slide configuration updates
    this.setupSocketListeners();
  }

  private setupSocketListeners() {
    // Deck updates
    this.socket.on('update_slides_config', (slidesConfig: any) => {
      this.playlistSource.next(slidesConfig['playlist']);
      this.setSelectedSlide(slidesConfig['playlist'][slidesConfig['playlist'].length - 1]);
      this.triggerScrollToSlide(slidesConfig['playlist'][slidesConfig['playlist'].length - 1]);
    });

    // Error handling
    this.socket.on('error', (error: any) => {
      console.error('Socket error:', error);
    });
  }
}
