import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input, OnChanges, OnDestroy,
  OnInit,
  Output, QueryList, SimpleChanges,
  ViewChild
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ISlide, ISlideObject, Slide, SlidePlaylist } from '../shared/slide';
import { Eventstatus } from '../shared/eventstatus';
import { EventconfigStoreService } from '../shared/eventconfig-store.service';
import { SlideStoreService } from '../shared/slide-store.service';
import { LogService } from '../shared/log.service';
import { VideoplayerComponent } from '../videoplayer/videoplayer.component';
import { PlayerStoreService } from '../shared/player-store.service';
import { environment } from '../../environments/environment';
import { Subscription } from 'rxjs';
import { SlidelistItemComponent } from './slidelist-item/slidelist-item.component';
@Component({
  selector: 'lse-slidelist',
  templateUrl: './slidelist.component.html',
  styleUrl: './slidelist.component.css',
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('1.5s ease-in', style({ height: 0, opacity: 1 }))
      ]),
      transition(':leave', [
        style({ height: 0, opacity: 1 }),
        animate('1s ease-in', style({ height: 0, opacity: 0 }))
      ])
    ]),
    trigger('slideInOut', [
      state(
        'in',
        style({
          overflow: 'hidden',
          height: '*'
        })
      ),
      state(
        'out',
        style({
          overflow: 'hidden',
          height: '0px'
        })
      ),
      transition('in => out', animate('250ms ease-in-out')),
      transition('out => in', animate('250ms ease-in-out'))
    ])
  ]
})

export class SlidelistComponent implements OnInit, OnDestroy {
  @ViewChild('slideHistoryChild', { read: ElementRef })
  public slideHistoryChild: ElementRef<any>;
  @ViewChild('slideHistoryContent', { read: ElementRef })
  public slideHistoryContent: ElementRef<any>;
  @ViewChild('slideDiv') slideDiv: ElementRef;

  @Input() phase: string;
  @Input() eStatus: Eventstatus;
  @Input() eLangGlob: any;
  @Input() slideIsCustom: any;
  @Input() eventFolder: string;
  @Input() languages: string[];
  @Input() selectedLanguage: string;
  @Output() playAtEvent = new EventEmitter();
  @Output() showElement = new EventEmitter();


  // playlist: Slide[];
  holdMyTime: any;
  path: string;
  slideIndex: number;
  hasScrolled = false;
  scrollMax = 0;
  showSlideFlow = 'in';
  screenwidth = document.body.offsetWidth;

  private readonly subscriptions : Subscription[] = [];

  private slideStoreService: SlideStoreService = inject(SlideStoreService);

  public slidePlaylist: SlidePlaylist[] = [];
  public selectedSlideObject: SlidePlaylist;


  constructor() {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.slideStoreService.playlist$.subscribe((playlist: SlidePlaylist[]) => {
        this.slidePlaylist = [...playlist];
      }),
      this.slideStoreService.selectedSlide$.subscribe((slide: SlidePlaylist) => {
        this.selectedSlideObject = slide;
      }),
      this.slideStoreService.scrollToSlide$.subscribe((slide) => {
        setTimeout(() => {
          this.scrollToSlide(slide);
        }, 100);
      })
    );
    setTimeout(() => {
      this.scrollToSlide(this.selectedSlideObject);
    }, 100);
  }

  get selectedSlideObjectIndex() {
    return this.slidePlaylist.findIndex((x) => x._id === this.selectedSlideObject?._id);
  }


  toggleSlideflow() {
    this.showSlideFlow = this.showSlideFlow === 'out' ? 'in' : 'out';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenwidth = window.innerWidth;
  }

  onshowElement(e: string) {
    this.showElement.emit(e);
  }

  selectSlide(slide: SlidePlaylist) {
    this.scrollToSlide(slide);
    this.slideStoreService.setSelectedSlide(slide);
  }

  handleEvent(value) {
    this.holdMyTime = value;
    this.playAtEvent.emit(value);
  }

  playAtFunction(timestamp) {
    console.log('triggered playatfunc', timestamp);
    this.playAtEvent.emit(timestamp);
  }

  scroll(direction: string) {
    console.log('scroll() direction:', direction);
    if (direction === 'left') {
      this.hasScrolled = true;
      this.slideHistoryContent.nativeElement.scrollTo({
        left: this.slideHistoryContent.nativeElement.scrollLeft - 150,
        behavior: 'smooth'
      });
    } else if (direction === 'right') {
      this.slideHistoryContent.nativeElement.scrollTo({
        left: this.slideHistoryContent.nativeElement.scrollLeft + 150,
        behavior: 'smooth'
      });
      if (
        this.slideHistoryContent.nativeElement.scrollLeft ===
        this.slideHistoryContent.nativeElement.scrollWidth -
        this.slideHistoryContent.nativeElement.clientWidth
      ) {
        this.hasScrolled = false;
      }
      if (this.phase === 'post-live') {
        this.hasScrolled = true;
      }
    }
  }

  scrollToSlide(slide: SlidePlaylist) {
    const index = this.slidePlaylist.findIndex((x) => x._id === slide?._id);
    const e =
      index *
      (this.slideHistoryContent.nativeElement.scrollWidth / this.slidePlaylist?.length) -
      (this.slideHistoryContent.nativeElement.offsetWidth / 2 - 70);
    this.slideHistoryContent.nativeElement.scrollTo({
      left: e,
      behavior: 'smooth'
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}

